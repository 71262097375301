import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

export default function Preview({ children, align, ...props }) {
  return (
    <div className={`text-${align}`}>
      <Button {...props}>{children}</Button>
    </div>
  );
}

Preview.propTypes = {
  value: PropTypes.node,
};
