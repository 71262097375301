import React from 'react';
import Layout from '../components/layout';

export default ({ title, heading, subheading, button, children }) => (
  <Layout heading={heading} subheading={subheading} button={button}>
    <section className="page-section">
      <div className="container">{children}</div>
    </section>
  </Layout>
);
