import React from 'react';
import { graphql } from 'gatsby';
import rehypeReact from 'rehype-react';
import GenericPlain from './genericPlain';
import Button from '../cms/widget-button/Preview';
import SEO from '../components/seo';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 'd-button': Button },
}).Compiler;

export default ({
  data: {
    markdownRemark: { frontmatter, htmlAst },
  },
}) => (
  <>
    <SEO title={frontmatter.title} />
    <GenericPlain {...frontmatter}>{renderAst(htmlAst)}</GenericPlain>
  </>
);

export const pageQuery = graphql`
  query GenericPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        heading
        subheading
        button
      }
    }
  }
`;
